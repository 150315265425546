// App.scss

// Include any default variable overrides here (though functions won't be available)
// Invoke

$primary: #A61E2F;
$secondary: #D0D1CE;

@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here
// Invoke Colors
$custom-colors: (
"ida": #ff5c5c,
"human": #5199e4
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

/**
* ==============================================
* Dot Typing
* ==============================================
*/
.dot-typing {
position: relative;
left: -9999px;
width: 10px;
height: 10px;
border-radius: 4px;
background-color: #dbdbdb;
margin-top: 3rem !important;
margin-bottom: 3rem !important;
color: #dbdbdb;
box-shadow: 9984px 0 0 0 #dbdbdb, 9999px 0 0 0 #dbdbdb, 10014px 0 0 0 #dbdbdb;
animation: dot-typing 1.0s infinite linear;
}

@keyframes dot-typing {
0% {
box-shadow: 9984px 0 0 0 #dbdbdb, 9999px 0 0 0 #dbdbdb, 10014px 0 0 0 #dbdbdb;
}

16.667% {
box-shadow: 9984px -10px 0 0 #dbdbdb, 9999px 0 0 0 #dbdbdb, 10014px 0 0 0 #dbdbdb;
}

33.333% {
box-shadow: 9984px 0 0 0 #dbdbdb, 9999px 0 0 0 #dbdbdb, 10014px 0 0 0 #dbdbdb;
}

50% {
box-shadow: 9984px 0 0 0 #dbdbdb, 9999px -10px 0 0 #dbdbdb, 10014px 0 0 0 #dbdbdb;
}

66.667% {
box-shadow: 9984px 0 0 0 #dbdbdb, 9999px 0 0 0 #dbdbdb, 10014px 0 0 0 #dbdbdb;
}

83.333% {
box-shadow: 9984px 0 0 0 #dbdbdb, 9999px 0 0 0 #dbdbdb, 10014px -10px 0 0 #dbdbdb;
}

100% {
box-shadow: 9984px 0 0 0 #dbdbdb, 9999px 0 0 0 #dbdbdb, 10014px 0 0 0 #dbdbdb;
}
}

.ida_bubble {
background-color: #e8e0e0;
color: rgb(55, 55, 55);
}

.btn-ida {
background-color: #ff5c5c !important;
border-color: #311a1a !important;

color: #fff8f8 !important;
}

.btngrp-ida {
background-color: #9a9a9a !important;
border-color: #311a1a !important;

color: #dfdfdf !important;
}


.human_bubble {
background-color: #5199e4;
color: rgb(255, 255, 255);
}

.btn-human {
background-color: #045ab6 !important;
color: rgb(255, 255, 255) !important;
}